import CallBackFunc from 'interface/callbackFunc';
import React from 'react';

interface Props {
  className?: string;
  onClick?: CallBackFunc<any, any>;
}

const Arrow = ({ className, onClick }: Props) => {
  return (
    <svg
      width="101"
      height="9"
      viewBox="0 0 101 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
    >
      <path
        d="M91.8225 -1.56599e-05L101 4.49999L91.8225 9L93.3913 5.45061L1.55149e-07 5.45061L2.38254e-07 3.54937L93.3913 3.54937L91.8225 -1.56599e-05Z"
        fill="currentColor"
        shapeRendering="geometricPrecision"
      />
    </svg>
  );
};

export default Arrow;
